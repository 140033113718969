// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCdKOx9ycDra0DPuZPTJTYbZt_AzmUJ5B8',
  authDomain: 'reynolds-codes.firebaseapp.com',
  projectId: 'reynolds-codes',
  storageBucket: 'reynolds-codes.appspot.com',
  messagingSenderId: '881202199201',
  appId: '1:881202199201:web:e3cf0ceeccd941e2154ee0',
  measurementId: 'G-JGDBPGB0JW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const logAnalyticsEvent = (
  eventName: string,
  params?: { [key: string]: any },
) => {
  logEvent(analytics, eventName, params);
};
