import React from 'react';
import moment from 'moment';

import { Wrapper, Row, Column1, TextWrapper, Body, Column2 } from './elements';
import { Path, SVGContainer } from '../../constants/global';

import MemojiLarge from '../memoji-large/index';
import Mac from '../../images/memoji/mac.png';
import { CoreSectionHeader } from '../core-section-header';

const About = () => {
  const age = moment(Date.now()).diff([1998, 5, 2], 'years');

  return (
    <SVGContainer primary={true} id='about'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1440 320'
        id='about-svg'
      >
        <Path
          primary={false}
          fillOpacity='1'
          d='M0,128L48,128C96,128,192,128,288,112C384,96,480,64,576,80C672,96,768,160,864,208C960,256,1056,288,1152,266.7C1248,245,1344,171,1392,133.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
        ></Path>
      </svg>

      <Wrapper>
        <Row>
          <Column1>
            <CoreSectionHeader header='About me' />
            <TextWrapper>
              <Body>
                Hi, my name is Reece Reynolds. I am a {age} year old Software
                Engineer &#x1F4F1;, Formula 1 fan &#x1F3CE; and a coffee
                enthusiast &#x2615; (like most developers) from Essex, UK. I
                hold a Bachelors of Science (Hons) degree in Computer Science
                from Swansea University and have industry experience building
                mobile applications using React Native and NativeScript Angular.
              </Body>
            </TextWrapper>
          </Column1>

          <Column2>
            <MemojiLarge
              imagePath={Mac}
              altText='Reece Memoji with Mac'
              primary={true}
            />
          </Column2>
        </Row>
      </Wrapper>
    </SVGContainer>
  );
};

export default About;
