import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IGlobalTheme } from '../../constants/theme';

export const Container = styled.footer<{ theme: IGlobalTheme }>`
  background-color: ${({ theme }) => theme.background};
  transition: 0.35s;
`;

export const Wrapper = styled.div`
  padding: 0 24px 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const Logo = styled(Link)<{ theme: IGlobalTheme }>`
  color: ${({ theme }) => theme.text};
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 600;
  transition: 0.35s;

  :link {
    color: ${({ theme }) => theme.text};
  }

  :visited {
    color: ${({ theme }) => theme.text};
  }
`;

export const WebsiteRights = styled.small<{ theme: IGlobalTheme }>`
  color: ${({ theme }) => theme.text};
  margin-bottom: 16px;
  transition: 0.35s;
`;

export const SocialIcons = styled.div<{ theme: IGlobalTheme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
`;

export const SocialLink = styled.a`
  font-size: 1.6rem;
  transition: 0.35s;

  :link {
    color: ${({ theme }) => theme.text};
  }

  :visited {
    color: ${({ theme }) => theme.text};
  }
`;
