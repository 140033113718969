import React, { useCallback } from 'react';
import { FiGithub } from 'react-icons/fi';
import { FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { animateScroll } from 'react-scroll';

import {
  Container,
  Wrapper,
  Logo,
  WebsiteRights,
  SocialIcons,
  SocialLink,
} from './elements';
import { Path, SVGContainer } from '../../constants/global';
import { logAnalyticsEvent } from '../../firebase';

const Footer = () => {
  const onLogoPressed = useCallback(() => {
    logAnalyticsEvent('footer_logo_pressed');
    animateScroll.scrollToTop();
  }, []);

  const onSocialPressed = useCallback(
    (name: 'email' | 'github' | 'linkedin') => {
      logAnalyticsEvent('footer_social_icon_pressed', { name });
    },
    [],
  );

  return (
    <SVGContainer primary={false}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
        <Path
          primary={true}
          fillOpacity='1'
          d='M0,32L48,58.7C96,85,192,139,288,165.3C384,192,480,192,576,170.7C672,149,768,107,864,101.3C960,96,1056,128,1152,160C1248,192,1344,224,1392,240L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
        ></Path>
      </svg>
      <Container>
        <Wrapper>
          <Logo to='/' onClick={onLogoPressed}>
            Reece Reynolds.
          </Logo>
          <WebsiteRights>
            Reece Reynolds ©{new Date().getFullYear()} All rights reserved.
          </WebsiteRights>

          <SocialIcons>
            <SocialLink
              href='mailto:reece@reynolds.codes'
              aria-label='Email'
              onClick={() => onSocialPressed('email')}
            >
              <HiOutlineMail />
            </SocialLink>

            <SocialLink
              href='https://github.com/ReeceReynolds'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Github'
              onClick={() => onSocialPressed('github')}
            >
              <FiGithub />
            </SocialLink>

            <SocialLink
              href='https://www.linkedin.com/in/reece-reynolds/'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='LinkedIn'
              onClick={() => onSocialPressed('linkedin')}
            >
              <FaLinkedin />
            </SocialLink>
          </SocialIcons>
        </Wrapper>
      </Container>
    </SVGContainer>
  );
};

export default Footer;
