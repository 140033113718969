import styled from 'styled-components';
import { IGlobalTheme } from './theme';

export const Heading1 = styled.h1<{ theme: IGlobalTheme }>`
  color: ${({ theme }) => theme.text};
  font-size: 3rem;
  text-align: center;
  transition: 0.35s;

  @media screen and (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

export const Heading2 = styled.h2<{ theme: IGlobalTheme }>`
  color: ${({ theme }) => theme.text};
  font-weight: 600;
  margin-top: 48px;
  margin-bottom: 16px;
  transition: 0.35s;
`;

export const Paragraph = styled.p``;
