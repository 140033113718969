import styled from 'styled-components';
import { Link } from 'react-scroll';
import { IGlobalTheme, PRIMARY } from '../../constants/theme';

export const Container = styled.li`
  height: 80px;
`;

export const ItemLink = styled(Link)<{ theme: IGlobalTheme }>`
  color: ${({ theme }) => theme.text};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 24px;
  height: 100%;
  cursor: pointer;
  transition: 0.35s;

  :hover {
    font-size: 1.1rem;
  }

  &.active {
    transition: 0.1s;
    font-size: 1.1rem;
    border-bottom: 2px solid ${PRIMARY};
  }

  @media screen and (max-width: 860px) {
    padding: 0 14px;
  }
`;
