import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import Confetti from '../../images/lottie/confetti.json';
import Error from '../../images/lottie/error.json';
import { FiGithub } from 'react-icons/fi';
import { FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

import {
  Wrapper,
  FormContainer,
  Label,
  Input,
  Body,
  TextArea,
  SubmitButton,
  SocialContainers,
  SocialIcons,
  SocialLink,
  Success,
  FeedbackContainer,
  Row,
  Column1,
  TextWrapper,
  Column2,
} from './elements';
import { Path, SVGContainer } from '../../constants/global';
import { CoreSectionHeader } from '../core-section-header';
import { logAnalyticsEvent } from '../../firebase';

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  const handleInputChange = (e: any) => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;

      case 'email':
        setEmail(e.target.value);
        break;

      case 'message':
        setMessage(e.target.value);
        break;

      default:
        break;
    }
  };

  const onSocialPressed = useCallback(
    (name: 'email' | 'github' | 'linkedin') => {
      logAnalyticsEvent('contact_social_icon_pressed', { name });
    },
    [],
  );

  const handleSubmit = (e: any) => {
    const state = { name, email, message };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...state }),
    })
      .then(() => {
        setResponse('success');
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch(() => setResponse('error'));

    e.preventDefault();
  };

  useEffect(() => {
    if (response) {
      setTimeout(() => {
        setResponse('');
      }, 5000);
    }
  }, [response]);

  return (
    <SVGContainer primary={true} id='contact'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1440 320'
        id='contact-svg'
      >
        <Path
          primary={false}
          fillOpacity='1'
          d='M0,256L60,234.7C120,213,240,171,360,138.7C480,107,600,85,720,101.3C840,117,960,171,1080,197.3C1200,224,1320,224,1380,224L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
        ></Path>
      </svg>

      <Wrapper>
        <CoreSectionHeader header='Get in touch' center />
        <Row>
          <Column1>
            <TextWrapper>
              <Body className='extra-space'>
                Do you have a website or mobile app project that requires a
                software developer 💻? I am open to freelancing opportunities!
                From building out your dream personal portfolio 💭 to
                constructing an intricate hybrid mobile app ⚙️, I will be more
                than happy to assist with your project.
              </Body>
              <Body>
                To get in touch with me, fill in the contact form, or connect in
                the socials section.
              </Body>
            </TextWrapper>
          </Column1>

          <Column2>
            <FormContainer>
              {!response ? (
                <form name='contact' method='post' onSubmit={handleSubmit}>
                  <input type='hidden' name='form-name' value='contact' />
                  <Label>Name</Label>
                  <Input
                    type='text'
                    name='name'
                    placeholder='Enter name...'
                    onChange={handleInputChange}
                    value={name}
                  />

                  <Label>Email address</Label>
                  <Input
                    type='email'
                    name='email'
                    placeholder='Enter email address...'
                    onChange={handleInputChange}
                    value={email}
                  />

                  <Label>Message</Label>
                  <TextArea
                    name='message'
                    placeholder='Enter message...'
                    rows={5}
                    onChange={handleInputChange}
                    value={message}
                  ></TextArea>

                  <SubmitButton type='submit'>Submit</SubmitButton>
                </form>
              ) : null}

              {response === 'success' ? (
                <Success>
                  <Lottie animationData={Confetti} loop={false} />
                  <FeedbackContainer style={{ position: 'absolute' }}>
                    <CoreSectionHeader header='Success' center />
                    <Body style={{ maxWidth: '28ch' }}>
                      Message successfully sent! I aim to respond within 2
                      working days.
                    </Body>
                  </FeedbackContainer>
                </Success>
              ) : null}

              {response === 'error' ? (
                <Success>
                  <Lottie animationData={Error} style={{ width: '60%' }} />
                  <FeedbackContainer>
                    <Body style={{ maxWidth: '28ch' }}>
                      There was an error sending your message. Please try again.
                    </Body>
                  </FeedbackContainer>
                </Success>
              ) : null}
            </FormContainer>
          </Column2>
        </Row>
      </Wrapper>

      <Wrapper>
        <Body>Or connect via socials</Body>

        <SocialContainers>
          <SocialIcons>
            <SocialLink
              href='mailto:reece@reynolds.codes'
              aria-label='Email'
              onClick={() => onSocialPressed('email')}
            >
              <HiOutlineMail />
            </SocialLink>

            <SocialLink
              href='https://github.com/ReeceReynolds'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Github'
              onClick={() => onSocialPressed('github')}
            >
              <FiGithub />
            </SocialLink>

            <SocialLink
              href='https://www.linkedin.com/in/reece-reynolds/'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='LinkedIn'
              onClick={() => onSocialPressed('linkedin')}
            >
              <FaLinkedin />
            </SocialLink>
          </SocialIcons>
        </SocialContainers>
      </Wrapper>
    </SVGContainer>
  );
};

export default ContactForm;
