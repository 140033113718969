export interface IGlobalTheme {
  primary: string;
  background: string;
  secondaryBackground: string;
  navBackground: string;
  text: string;
  textAlt: string;
  textOpaque: string;
  memojiShadow: string;
  boxShadow: string;
  memojiBackground: string;
  memojiBorder: string;
}

export const PRIMARY = '#BACADE';

export const lightTheme: IGlobalTheme = {
  primary: PRIMARY,
  background: '#fff',
  secondaryBackground: '#F5F5F5',
  navBackground: 'rgba(255,255,255,1)',
  text: 'rgb(13, 14, 16)',
  textAlt: 'rgb(13, 14, 16)',
  textOpaque: 'rgba(13, 14, 16, 0.55)',
  memojiShadow: '10px 10px 20px #acb7c5,-10px -10px 20px #f8ffff;',
  boxShadow: '0px 4px 12px 00px  rgba(13,14,16,0.08)',
  memojiBackground: '#E0E0E0',
  memojiBorder: 'solid #B8B8B8 2px',
};

export const darkTheme: IGlobalTheme = {
  primary: PRIMARY,
  background: 'rgb(13, 14, 16)',
  secondaryBackground: '#1C1D22',
  navBackground: 'rgba(13,14,16,1)',
  text: 'rgba(255,255,255,0.87)',
  textAlt: 'rgb(13, 14, 16)',
  textOpaque: 'rgba(255, 255, 255, 0.55)',
  memojiShadow: '10px 10px 20px #050606,-10px -10px 20px #15161a;',
  boxShadow: '0px 4px 12px 0px  rgba(47,47,47,0.25)',
  memojiBackground: '#373943',
  memojiBorder: 'solid #5C5F70 2px',
};
