import React, { useEffect } from 'react';
import { logAnalyticsEvent } from '../firebase';

import Navbar from '../components/navbar';
import Hero from '../components/hero';
import About from '../components/about';
import Skills from '../components/skills';
import Experience from '../components/experience';
import Projects from '../components/projects';
import ContactForm from '../components/contact-form';
import Footer from '../components/footer';

const Home = () => {
  useEffect(() => {
    logAnalyticsEvent('firebase_init');
  }, []);

  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <Skills />
      <Experience />
      <Projects />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Home;
