import React, { useCallback, useContext } from 'react';
import { RiMoonClearFill } from 'react-icons/ri';
import { HiSun } from 'react-icons/hi';
import Switch from 'react-switch';
import { PRIMARY } from '../../constants/theme';
import { ThemeContext } from '../../context/ThemeStore';
import { logAnalyticsEvent } from '../../firebase';

const ThemeSwitch = () => {
  const { theme, switchTheme } = useContext(ThemeContext);

  const onSwitchTheme = useCallback(() => {
    logAnalyticsEvent('theme_toggle', {
      from: theme,
      to: theme === 'light' ? 'dark' : 'light',
    });
    switchTheme();
  }, [switchTheme, theme]);

  return (
    <Switch
      checked={theme === 'light'}
      onChange={onSwitchTheme}
      height={28}
      width={64}
      handleDiameter={30}
      onColor={PRIMARY}
      onHandleColor='#3D3D3D'
      offColor='#3D3D3D'
      offHandleColor={PRIMARY}
      boxShadow='0px 1px 6px rgba(0, 0, 0, 0.4)'
      activeBoxShadow='0px 0px 1px 4px rgba(0, 0, 0, 0.2)'
      checkedIcon={
        <HiSun
          color='#3D3D3D'
          style={{ fontSize: 20, marginLeft: 6, marginTop: 4 }}
        />
      }
      uncheckedIcon={
        <RiMoonClearFill
          color={PRIMARY}
          style={{ fontSize: 18, marginLeft: 8, marginTop: 6 }}
        />
      }
    />
  );
};

export default ThemeSwitch;
