import React from 'react';
import Tilt from 'react-parallax-tilt';
import Typist from 'react-typist';
import { Container } from './elements';
import { Heading2 } from '../../constants/typography';
import MemojiBackground from '../memoji-background';
import Smile from '../../images/memoji/smile.png';
import { CoreSectionHeader } from '../core-section-header';

const Hero = () => {
  return (
    <>
      <Container>
        <Tilt scale={1.1} gyroscope>
          <MemojiBackground imagePath={Smile} />
        </Tilt>

        <Heading2>Hey, I'm Reece &#9996;</Heading2>

        <CoreSectionHeader header=''>
          <div style={{ minHeight: 72 }}>
            <Typist
              avgTypingDelay={150}
              startDelay={1500}
              cursor={{
                hideWhenDone: true,
                hideWhenDoneDelay: 150,
              }}
            >
              Software Engineer
            </Typist>
          </div>
        </CoreSectionHeader>
      </Container>
    </>
  );
};

export default Hero;
