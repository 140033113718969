import React, { useCallback } from 'react';
import { logAnalyticsEvent } from '../../../firebase';

import { Container, Icon } from './elements';

type Props = {
  icon: any;
  link: string;
};

const IconAndText = ({ icon, link }: Props) => {
  const onClick = useCallback(() => {
    logAnalyticsEvent('technical_skill_pressed', { link });
  }, [link]);

  return (
    <Container onClick={onClick}>
      <a href={link} target='_blank' rel='noopener noreferrer'>
        <Icon>{icon}</Icon>
      </a>
    </Container>
  );
};

export default IconAndText;
