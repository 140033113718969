import styled from 'styled-components';

export const Heading = styled.h1<{ center?: boolean; isProjects?: boolean }>`
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;
  text-align: ${(props) => (props.center ? 'center' : 'start')};

  @media screen and (max-width: ${(props) =>
      props.isProjects ? '925px' : '768px'}) {
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ChildrenHeading = styled.h1<{
  center?: boolean;
  isProjects?: boolean;
}>`
  font-size: 48px;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;
  text-align: ${(props) => (props.center ? 'center' : 'start')};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;
