import styled from 'styled-components';
import { IGlobalTheme } from '../../constants/theme';

type Props = {
  primary: boolean;
};

export const Container = styled.div<{ theme: IGlobalTheme }>`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.35s;
  position: relative;
  max-height: 360px;
  max-width: 360px;
  min-height: 120px;
  min-width: 120px;
`;

export const Blob = styled.svg`
  height: 100%;
  width: 100%;
`;

export const BlobPath = styled.path<Props>`
  ${(props) => {
    if (props.primary) {
      return `
        fill: ${props.theme.background};
    `;
    } else {
      return `
      fill: ${props.theme.secondaryBackground};
      `;
    }
  }}
  transition: 0.35s;
`;

export const Image = styled.img`
  width: 50%;
  height: 50%;

  position: absolute;
  left: 25%;
  top: 25%;
  z-index: 150;
`;
