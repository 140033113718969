import styled from 'styled-components';
import { IGlobalTheme } from './theme';

type Props = {
  theme: IGlobalTheme;
  primary: boolean;
};

export const SVGContainer = styled.div<Props>`
  ${(props) => {
    if (props.primary) {
      return `
        background-color: ${props.theme.secondaryBackground};
    `;
    } else {
      return `
      background-color: ${props.theme.background};
      `;
    }
  }}
  transition: 0.35s;
`;

export const Path = styled.path<Props>`
  ${(props) => {
    if (props.primary) {
      return `
        fill: ${props.theme.secondaryBackground};
    `;
    } else {
      return `
      fill: ${props.theme.background};
      `;
    }
  }}
  transition: 0.35s;
`;
