import React, { useCallback, useMemo } from 'react';
import { SVGContainer, Path } from '../../constants/global';

import { Wrapper, Row, Column1, TextWrapper, Column2 } from './elements';

import MemojiLarge from '../memoji-large';

import Celebrate from '../../images/memoji/celebrate.png';
import NativeScript from '../../images/NativeScript.png';
import { CoreTile, CoreTileProps } from '../core-tile';
import { CoreSectionHeader } from '../core-section-header';

const Projects = () => {
  const projects: Pick<
    CoreTileProps,
    'title' | 'subtitle' | 'tertiary' | 'description' | 'link' | 'logo'
  >[] = useMemo(
    () => [
      {
        logo: NativeScript,
        title: 'NativeScript WatchOS Connector',
        subtitle: 'npmjs',
        link: 'https://www.npmjs.com/package/nativescript-watchos-connector',
        description: [
          'When Apple Watch support was put into beta for NativeScript, I ran into an issue with passing data between the iOS and WatchOS apps. After finding a solution, I decided to publish this project to npm for other developers to import into their projects.',
        ],
      },
    ],
    [],
  );

  const renderFirstProject = useCallback(() => {
    const props = projects[0];
    return <CoreTile index={0} altBackground {...props} />;
  }, [projects]);

  return (
    <SVGContainer primary={false} id='projects'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1440 320'
        id='projects-svg'
      >
        <Path
          primary={true}
          fillOpacity='1'
          d='M0,128L60,122.7C120,117,240,107,360,122.7C480,139,600,181,720,176C840,171,960,117,1080,122.7C1200,128,1320,192,1380,224L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
        ></Path>
      </svg>

      <Wrapper>
        <Row>
          <Column1>
            <MemojiLarge
              imagePath={Celebrate}
              altText='Reece celebration Memoji'
              primary={false}
            />
          </Column1>

          <Column2>
            <TextWrapper>
              <CoreSectionHeader header='Projects' />
              {renderFirstProject()}
            </TextWrapper>
          </Column2>
        </Row>
      </Wrapper>
    </SVGContainer>
  );
};

export default Projects;
