import React, { useCallback } from 'react';
import { logAnalyticsEvent } from '../../firebase';
import { Container, ItemLink } from './elements';

type Props = {
  link: string;
  title: string;
};

const NavbarItem = ({ link, title }: Props) => {
  const svg = document.getElementById(`${link}-svg`)?.style.height;
  const height: number = svg ? parseInt(svg) / 2 : 0;

  const onClick = useCallback(() => {
    logAnalyticsEvent('navbar_item_pressed', { title });
  }, [title]);

  return (
    <Container>
      <ItemLink
        to={link}
        smooth={true}
        spy={true}
        offset={height}
        onClick={onClick}
      >
        {title}
      </ItemLink>
    </Container>
  );
};

export default NavbarItem;
