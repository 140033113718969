import React from 'react';
import { ChildrenHeading, Heading } from './elements';

interface CoreSectionHeaderProps {
  header: string;
  center?: boolean;
  children?: any;
}

export const CoreSectionHeader = ({
  header,
  center,
  children,
}: CoreSectionHeaderProps) => {
  if (children) {
    return <ChildrenHeading>{children}</ChildrenHeading>;
  }
  return (
    <Heading center={center} isProjects={header === 'Projects'}>
      {header}
    </Heading>
  );
};
