import styled from 'styled-components';
import { PRIMARY } from '../../constants/theme';

interface ContainerProps {
  topSpacing: boolean;
}

export const Container = styled.div<{ altBackground?: boolean }>`
  margin-bottom: 16px;
  padding-top: 32px;
  background-color: ${(props) =>
    props.altBackground
      ? props.theme.secondaryBackground
      : props.theme.background};
  border-radius: 32px;
  transition: 0.35s;
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: ${({ theme }) => theme.text};
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 0px 32px;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const Logo = styled.img`
  grid-area: col1;
  border-radius: 50%;
  height: 58px;
  width: 58px;
  margin-right: 24px;
  transition: 0.35s;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
    margin-bottom: 16px;
    height: 36px;
    width: 36px;
  }
`;

export const Link = styled.a`
  transition: 0.35s;
  :link {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }

  :visited {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }

  :hover {
    color: ${PRIMARY};
    transition: 0.35s;
  }
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  margin: 8px 0px;
`;

export const Tertiary = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.textOpaque};
  transition: 0.35s;
`;

export const Heading = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;
`;

export const ItemContent = styled.p<ContainerProps>`
  margin-top: ${(props) => (props.topSpacing ? '8px' : '0px')};
  max-width: 60ch;
  font-size: 16px;
  line-height: 1.4;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;
`;
