import React from 'react';
import { Container, Blob, BlobPath, Image } from './element';

type Props = {
  imagePath: string;
  altText: string;
  primary: boolean;
};

const MemojiLarge = ({ imagePath, altText, primary }: Props) => {
  return (
    <Container>
      <Blob
        id='visual'
        viewBox='0 0 400 400'
        width='400'
        height='400'
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
      >
        <g transform='translate(215.69488689596324 191.6216461739624)'>
          <BlobPath
            primary={primary}
            d='M126.8 -152.7C157.7 -125.3 171.4 -79 171.8 -36.3C172.2 6.4 159.3 45.5 138.5 78.6C117.8 111.8 89.1 139.1 50.5 164.1C11.9 189.2 -36.8 212.1 -69.5 197.4C-102.1 182.7 -118.7 130.4 -144.5 84.2C-170.3 38 -205.3 -2.2 -203.1 -39.5C-200.9 -76.8 -161.5 -111.2 -121.5 -136.9C-81.4 -162.7 -40.7 -179.8 3.6 -184.2C48 -188.5 96 -180.1 126.8 -152.7'
          ></BlobPath>
        </g>
      </Blob>
      <Image src={imagePath} alt={altText} draggable={false} />
    </Container>
  );
};

export default MemojiLarge;
