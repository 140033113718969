import React, { useState, createContext, useEffect } from 'react';

interface IThemeContext {
  theme: 'light' | 'dark';
  switchTheme: () => void;
}

const ThemeContext = createContext<IThemeContext>({
  theme: 'light',
  switchTheme: () => {},
}); // line A - creating the context

const ThemeStore = ({ children }: any) => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  const determineTheme = () => {
    const mq = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(mq.matches ? 'dark' : 'light');

    mq.addEventListener('change', function (evt) {
      setTheme(evt.matches ? 'dark' : 'light');
    });
  };

  useEffect(() => {
    determineTheme();
  }, []);

  const switchTheme = () => {
    const next = theme === 'light' ? 'dark' : 'light';
    setTheme(next);
  };

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeStore, ThemeContext };
