import React, { useCallback } from 'react';
import { animateScroll } from 'react-scroll';

import NavbarItem from '../navbar-item';
import {
  MenuContainer,
  Nav,
  NavbarContainer,
  NavbarLogo,
  NavbarMenu,
  ThemeContainer,
} from './elements';
import ThemeSwitch from '../theme-switch';
import { logAnalyticsEvent } from '../../firebase';

const Navbar = () => {
  const onClick = useCallback(() => {
    animateScroll.scrollToTop();
    logAnalyticsEvent('navbar_logo_pressed');
  }, []);

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavbarLogo to='/' onClick={onClick}>
            Reece Reynolds.
          </NavbarLogo>

          <MenuContainer>
            <NavbarMenu>
              <NavbarItem link='about' title='About' />
              <NavbarItem link='skills' title='Skills' />
              <NavbarItem link='experience' title='Experience' />
              <NavbarItem link='projects' title='Projects' />
              <NavbarItem link='contact' title='Contact' />
            </NavbarMenu>

            <ThemeContainer>
              <ThemeSwitch />
            </ThemeContainer>
          </MenuContainer>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
