import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { ThemeContext } from '../../context/ThemeStore';
import { logAnalyticsEvent } from '../../firebase';
import { Arrow, Container, DescriptionContainer, Gradient } from './elements';

interface CoreAccordionProps {
  children: any;
  title: string;
  initOpen?: boolean;
  altBackground?: boolean;
}

export const CoreAccordion = ({
  children,
  initOpen,
  altBackground,
  title,
}: CoreAccordionProps) => {
  const { theme } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(initOpen || false);
  const [storedTheme, setStoredTheme] = useState(theme);
  const [gradientClass, setGradientClass] = useState<{
    isOpen: 'fadeIn' | 'fadeOut';
    opacity: 'no-opacity' | '';
  }>({ isOpen: 'fadeIn', opacity: '' });

  useEffect(() => {
    if (storedTheme !== theme) {
      if (!isOpen) {
        setGradientClass((prev) => {
          return { ...prev, opacity: 'no-opacity' };
        });
        setTimeout(() => {
          setGradientClass((prev) => {
            return { ...prev, opacity: '' };
          });
        }, 100);
      }

      setStoredTheme(theme);
    }
  }, [isOpen, storedTheme, theme]);

  useEffect(() => {
    setGradientClass((prev) => {
      return { ...prev, isOpen: isOpen ? 'fadeOut' : 'fadeIn' };
    });
  }, [isOpen]);

  const handleOnClick = useCallback(() => {
    logAnalyticsEvent('accordion_pressed', {
      from: isOpen,
      to: !isOpen,
      title,
    });
    setIsOpen((prev) => !prev);
  }, [isOpen, title]);

  return (
    <Container onClick={handleOnClick}>
      <Arrow className={isOpen ? 'rotateOut' : 'rotateIn'}>
        <FaChevronDown />
      </Arrow>
      <Gradient
        altBackground={altBackground}
        className={`${gradientClass.isOpen} ${gradientClass.opacity}`}
      />
      <DescriptionContainer isOpen={isOpen}>{children}</DescriptionContainer>
    </Container>
  );
};
