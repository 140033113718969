import React from 'react';
import { Container, Image } from './elements';

type Props = {
  imagePath: string;
};

const MemojiBackground = ({ imagePath }: Props) => {
  return (
    <Container>
      <Image src={imagePath} alt='Reece Memoji icon' draggable={false} />
    </Container>
  );
};

export default MemojiBackground;
