import styled from 'styled-components';
import { IGlobalTheme } from '../../constants/theme';

export const Container = styled.div<{ theme: IGlobalTheme }>`
  background: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 32px 0 32px;
  z-index: 1;
  transition: 0.35s;

  @media screen and (max-width: 768px) {
    padding-top: 32px;
  }
`;
