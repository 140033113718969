import styled from 'styled-components';
import { IGlobalTheme } from '../../constants/theme';

export const Container = styled.div<{ theme: IGlobalTheme }>`
  height: 160px;
  width: 160px;
  background-color: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.memojiShadow};
  transition: 0.35s;
`;

export const Image = styled.img`
  width: 80%;
  height: 80%;
`;
