import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: minmax() (auto, 1fr);
  align-items: center;
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col1 col1' 'col2 col2';
  }
`;

export const Column1 = styled.div`
  padding: 16px;
  grid-area: col1;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 32px;
  }
`;

export const Column2 = styled.div`
  padding: 0 16px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 32px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 440px;
  padding-top: 0;
`;

export const Body = styled.p`
  max-width: 60ch;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;
`;
