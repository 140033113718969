import React, { useCallback, useMemo } from 'react';
import { Path, SVGContainer } from '../../constants/global';
import { Wrapper, Row, Column1, TextWrapper } from './elements';
import { CoreTile, CoreTileProps } from '../core-tile';
import Gobsmack from '../../images/companies/Gobsmack.png';
import Chase from '../../images/companies/Chase.png';
import { CoreSectionHeader } from '../core-section-header';

const Experience = () => {
  const roles: Pick<
    CoreTileProps,
    'title' | 'subtitle' | 'tertiary' | 'logo' | 'link' | 'description'
  >[] = useMemo(
    () => [
      {
        subtitle: 'JPMorgan Chase & Co.',
        tertiary: 'August 2021 to Present',
        title: 'Associate Software Engineer',
        logo: Chase,
        link: 'https://www.chase.co.uk/gb/en/',
        description: [
          'Chase is a new digital bank that has launched in the UK and our promise is to make banking a rewarding experience for our customers. The Chase app provides simple ways for customers to manage their money in the way that works best for them, with seamless access to our UK led team of customer support specialists and the reassurance of a brand that’s already trusted by millions in the US.',
          'Software Engineer at Chase, responsible for building and maintaining features of the mobile app.',
        ],
      },
      {
        logo: Gobsmack,
        subtitle: 'Gobsmack Rewards',
        tertiary: 'August 2019 to August 2021',
        title: 'Mobile App Developer',
        description: [
          'Gobsmack Rewards is a technology company responsible for delivering a digital customer hub and rewards program targeted at the insurance industry.',
          'During my time at Gobsmack I drove the rebuild of the mobile proposition into a vanilla React Native project. This proposition included a configurable “boilerplate” to allow new clients time to market become as low as possible.',
          'A normal day would include building the React Native proposition, being on hand to support all members of the mobile team with React Native methodologies and using NativeScript Angular to maintain the Admiral Rewards and More Than Rewards codebases in an agile environment.',
        ],
        link: 'https://gobsmack.co.uk/',
      },
    ],
    [],
  );

  const renderRoles = useCallback(() => {
    return roles.map((role, index) => (
      <CoreTile key={`experience-tile${index}`} index={index} {...role} />
    ));
  }, [roles]);

  return (
    <SVGContainer primary={true} id='experience'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1440 320'
        id='experience-svg'
      >
        <Path
          d='M0,192L48,181.3C96,171,192,149,288,144C384,139,480,149,576,176C672,203,768,245,864,245.3C960,245,1056,203,1152,186.7C1248,171,1344,181,1392,186.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'
          primary={false}
        ></Path>
      </svg>

      <Wrapper>
        <CoreSectionHeader header='Experience' center />
        <Row>
          <Column1>
            <TextWrapper>{renderRoles()}</TextWrapper>
          </Column1>
        </Row>
      </Wrapper>
    </SVGContainer>
  );
};

export default Experience;
