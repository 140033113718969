import styled, { keyframes } from 'styled-components';

export const FormContainer = styled.div`
  flex-direction: column;
  padding: 32px;
  background-color: ${({ theme }) => theme.background};
  border-radius: 25px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  max-width: 440px;
  transition: 0.35s;
  margin-right: auto;
  margin-left: auto;
`;

export const Label = styled.label`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;
`;

export const Input = styled.input`
  padding: 8px;
  width: 100%;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  margin-bottom: 32px;
  margin-top: 8px;
  transition: 0.35s;
  outline: none;
`;

export const TextArea = styled.textarea`
  padding: 8px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  margin-bottom: 24px;
  margin-top: 8px;
  transition: 0.35s;
  outline: none;
  width: 100%;
  resize: none;
`;

export const SubmitButton = styled.button`
  cursor: pointer;
  padding: 8px 32px;
  display: flex;
  text-align: center;
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  background-color: ${({ theme }) => theme.secondaryBackground};
  color: ${({ theme }) => theme.text};
  border-width: 2px;
  border-color: ${({ theme }) => theme.text};
  transition: 0.35s;
`;

export const Success = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.35s;
`;

export const SocialContainers = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const SocialIcons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
`;

export const SocialLink = styled.a`
  font-size: 1.6rem;
  transition: 0.35s;

  :link {
    color: ${({ theme }) => theme.text};
  }

  :visited {
    color: ${({ theme }) => theme.text};
  }
`;

const opacityShift = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

export const FeedbackContainer = styled.div`
  animation: ${opacityShift} 1.2s ease-in-out;
`;

export const Wrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  padding: 0 24px;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: minmax() (auto, 1fr);
  grid-template-areas: 'col1 col2';
  margin-bottom: 48px;

  @media screen and (max-width: 900px) {
    grid-template-areas: 'col1 col1' 'col2 col2';
    align-items: center;
  }
`;

export const Column1 = styled.div`
  padding: 0px 16px;
  grid-area: col1;

  @media screen and (max-width: 900px) {
    padding: 0;
    margin-bottom: 32px;
  }
`;

export const Column2 = styled.div`
  padding: 0 16px;
  grid-area: col2;

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

export const TextWrapper = styled.div`
  max-width: 440px;
  padding-top: 0;
`;

export const Body = styled.p`
  max-width: 60ch;
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.text};
  transition: 0.35s;

  &.extra-space {
    margin-bottom: 16px;
  }
`;
