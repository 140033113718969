import React from 'react';
import { Path, SVGContainer } from '../../constants/global';

import { Wrapper, Row, Column1, Column2 } from './elements';

import IconAndText from './IconAndTitle';

import {
  SiTypescript,
  SiRedux,
  SiNativescript,
  SiAngular,
  SiSass,
} from 'react-icons/si';
import { RiReactjsLine } from 'react-icons/ri';
import { CoreSectionHeader } from '../core-section-header';

const Skills = () => {
  return (
    <SVGContainer primary={false} id='skills'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1440 320'
        id='skills-svg'
      >
        <Path
          primary={true}
          fill-opacity='1'
          d='M0,128L60,122.7C120,117,240,107,360,122.7C480,139,600,181,720,176C840,171,960,117,1080,122.7C1200,128,1320,192,1380,224L1440,256L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z'
        ></Path>
      </svg>

      <Wrapper>
        <CoreSectionHeader header='Technical skills' center />

        <Row>
          <Column1>
            <IconAndText
              icon={<SiTypescript fontSize={28} />}
              link='https://www.typescriptlang.org/'
            />

            <IconAndText
              icon={<RiReactjsLine fontSize={28} />}
              link='https://reactnative.dev/'
            />

            <IconAndText
              icon={<SiRedux fontSize={28} />}
              link='https://redux.js.org/'
            />
          </Column1>

          <Column2>
            <IconAndText
              icon={<SiNativescript fontSize={28} />}
              link='https://nativescript.org/'
            />

            <IconAndText
              icon={<SiAngular fontSize={28} />}
              link='https://angular.io/'
            />

            <IconAndText
              icon={<SiSass fontSize={28} />}
              link='https://sass-lang.com/'
            />
          </Column2>
        </Row>
      </Wrapper>
    </SVGContainer>
  );
};

export default Skills;
