import React, { useCallback } from 'react';
import { CoreAccordion } from '../core-accordion';

import {
  Container,
  TitleWrapper,
  Logo,
  Heading,
  Subtitle,
  Tertiary,
  Link,
  ItemContent,
} from './elements';

export interface CoreTileProps {
  logo: string;
  title: string;
  subtitle: string;
  index: number;
  tertiary?: string;
  link?: string;
  description?: string[];
  altBackground?: boolean;
}

export const CoreTile = ({
  logo,
  title,
  subtitle,
  tertiary,
  link,
  description,
  altBackground,
  index,
}: CoreTileProps) => {
  const renderDescription = useCallback(
    () => (
      <CoreAccordion
        initOpen={index === 0}
        altBackground={altBackground}
        title={title}
      >
        <div>
          {description?.map((value, index) => (
            <ItemContent
              topSpacing={index !== 0}
              key={`${value.slice(5)}${index}`}
            >
              {value}
            </ItemContent>
          ))}
        </div>
      </CoreAccordion>
    ),
    [altBackground, description, index, title],
  );

  return (
    <Container altBackground={altBackground} id={`${title}${index}`}>
      <TitleWrapper>
        {logo &&
          (link ? (
            <Link href={link} target='_blank' rel='noopener noreferrer'>
              <Logo src={logo} alt={`${subtitle} logo`} draggable={false} />
            </Link>
          ) : (
            <Logo src={logo} alt={`${subtitle} logo`} draggable={false} />
          ))}

        <div>
          <Heading>{title}</Heading>
          {link ? (
            <Link href={link} target='_blank' rel='noopener noreferrer'>
              <Subtitle>{subtitle}</Subtitle>
            </Link>
          ) : (
            <Subtitle>{subtitle}</Subtitle>
          )}

          {tertiary && <Tertiary>{tertiary}</Tertiary>}
        </div>
      </TitleWrapper>

      {description && renderDescription()}
    </Container>
  );
};
