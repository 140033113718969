import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeStore } from './context/ThemeStore';
import Theme from './context/Theme';

import './App.css';
import Home from './pages';

function App() {
  return (
    <ThemeStore>
      <Theme>
        <BrowserRouter>
          <Home />
        </BrowserRouter>
      </Theme>
    </ThemeStore>
  );
}

export default App;
