import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { IGlobalTheme } from '../../constants/theme';

export const Nav = styled.nav<{ theme: IGlobalTheme }>`
  background: ${({ theme }) => theme.navBackground};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: 0.35s;

  @media screen and (max-width: 768px) {
    position: relative;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavbarLogo = styled(RouterLink)`
  color: ${({ theme }) => theme.text};
  justify-self: flex-start;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;
  transition: 0.35s;
  font-size: 1.2rem;

  @media screen and (max-width: 900px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NavbarMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ThemeContainer = styled.div`
  display: flex;
  align-items: center;
`;
