import styled, { keyframes } from 'styled-components';
// import { PRIMARY } from '../../constants/theme';

const fadeIn = keyframes`
0% { opacity: 0; }
25% { opacity: 0; }
100% { opacity: 1; }
`;

const fadeOut = keyframes`
0% { opacity: 1; }
40% { opacity: 1; }
100% { opacity: 0; }
`;

const rotateIn = keyframes`
0% {transform: rotate(180deg);}
100% {transform: rotate(0deg);}
`;

const rotateOut = keyframes`
0% {transform: rotate(0deg);}
100% {transform: rotate(180deg);}
`;

export const Container = styled.div`
  position: relative;
  padding-bottom: 32px;
  border-radius: 0px 0px 32px 32px;
  overflow: hidden;
`;

export const Arrow = styled.div`
  position: absolute;
  bottom: 16px;
  right: 50%;
  z-index: 100;

  &.rotateIn {
    animation: 0.5s ease 0s normal forwards 1 ${rotateIn};
  }

  &.rotateOut {
    animation: 0.5s ease 0s normal forwards 1 ${rotateOut};
  }
`;

export const Gradient = styled.div<{ altBackground?: boolean }>`
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  transition: 0.35s;
  background-image: linear-gradient(
    to top,
    ${({ theme, altBackground }) =>
        altBackground ? theme.secondaryBackground : theme.background}
      20%,
    transparent
  );

  &.fadeIn {
    animation: 0.35s ease 0s normal forwards 1 ${fadeIn};
  }

  &.fadeOut {
    animation: 0.35s ease 0s normal forwards 1 ${fadeOut};
  }

  &.no-opacity {
    background-image: linear-gradient(to top, transparent, transparent);
    transition: 0s;
  }
`;

export const DescriptionContainer = styled.div<{ isOpen: boolean }>`
  transition: 1s;
  max-height: ${(props) => (props.isOpen ? '600px' : '56px')};
  padding: 0px 32px 16px;
`;
