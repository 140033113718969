import styled from 'styled-components';
import { PRIMARY } from '../../../constants/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.div`
  transition: 0.35s;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  background-color: ${({ theme }) => theme.secondaryBackground};
  color: ${({ theme }) => theme.text};

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 8px;

  :hover {
    background-color: ${PRIMARY};
    transition: 0.35s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;
